<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Warehouse</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <Warehouse
            :value="this.selectedWarehouse"
            @selected="(val) => { this.selectedWarehouse = val }"
          ></Warehouse>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Counting Date</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <Date
            :default="false"
            @selected="(val) => { this.countingDate = val }"
          ></Date>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
        </div>
      </div>
    </div>

    <div class="vx-row mt-3">
      <div class="vx-col w-full mb-base">
        <Table
          :draw="draw"
          :selectedWarehouse="selectedWarehouse"
          :countingDate="countingDate"
          :status="status"
          :isApproval="isApproval"
          :tab="activeTab"
        ></Table>
      </div>
    </div>
  </div>
</template>

<script>
import Date from "../component/Date.vue";
import Table from "../component/Table.vue";
import Warehouse from "../component/Warehouse.vue";

export default {
  components: {
    Warehouse,
    Table,
    Date,
  },
  data() {
    return {
      draw: 0,
      selectedWarehouse: null,
      countingDate: null,
      status: 2,
      isApproval: 2,
      activeTab: "ApprovedApproval",
    };
  },
  methods: {
    handleFilter() {
      this.draw++;
    },
  },
}
</script>
